import React, { useRef, useState, useEffect } from "react"
import Footer from "../components/Footer/Footer"
import Header from "../components/Header"
import Navbar from "../components/Navbar/Navbar"

import GoogleMapReact from "google-map-react"

import emailjs, { send } from "@emailjs/browser"

import { RiMapPin2Fill } from "react-icons/ri"

import Arrow from "../assets/arrows/sendButtonArrow.svg"
import ArrowGreen from "../assets/arrows/sendButtonArrowGreen.svg"
import { useTheme } from "../components/ThemeProvider/ThemeProvider"
import { useTranslation } from "../i18n"
import { Helmet } from "react-helmet"
import i18n from 'i18next'


const contact = () => {
	const { t } = useTranslation("contact")
	useEffect(() => {
		i18n.changeLanguage("en")
		  },
	[]);
	const defaultProps = {
		center: {
			lat: 35.65650534837498,
			lng: 139.71506572053738,
		},
		zoom: 18,
	}

	const { colorMode } = useTheme()

	const createMapOptions = (maps: GoogleMapReact.Maps) => {
		if (colorMode === "dark") {
			return {
				panControl: false,
				mapTypeControl: false,
				scrollwheel: false,
				streetViewControl: true,
				streetViewControlOptions: {
					position: maps.ControlPosition.RIGHT_BOTTOM,
				},
				styles: [
					{
						featureType: "all",
						elementType: "labels.text.fill",
						stylers: [
							{
								saturation: 36,
							},
							{
								color: "#000000",
							},
							{
								lightness: 40,
							},
						],
					},
					{
						featureType: "all",
						elementType: "labels.text.stroke",
						stylers: [
							{
								visibility: "on",
							},
							{
								color: "#000000",
							},
							{
								lightness: 16,
							},
						],
					},
					{
						featureType: "all",
						elementType: "labels.icon",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "administrative",
						elementType: "geometry.fill",
						stylers: [
							{
								color: "#000000",
							},
							{
								lightness: 20,
							},
						],
					},
					{
						featureType: "administrative",
						elementType: "geometry.stroke",
						stylers: [
							{
								color: "#000000",
							},
							{
								lightness: 17,
							},
							{
								weight: 1.2,
							},
						],
					},
					{
						featureType: "landscape",
						elementType: "geometry",
						stylers: [
							{
								color: "#000000",
							},
							{
								lightness: 20,
							},
						],
					},
					{
						featureType: "poi",
						elementType: "geometry",
						stylers: [
							{
								color: "#000000",
							},
							{
								lightness: 21,
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "geometry.fill",
						stylers: [
							{
								color: "#000000",
							},
							{
								lightness: 17,
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "geometry.stroke",
						stylers: [
							{
								color: "#000000",
							},
							{
								lightness: 29,
							},
							{
								weight: 0.2,
							},
						],
					},
					{
						featureType: "road.arterial",
						elementType: "geometry",
						stylers: [
							{
								color: "#000000",
							},
							{
								lightness: 18,
							},
						],
					},
					{
						featureType: "road.local",
						elementType: "geometry",
						stylers: [
							{
								color: "#000000",
							},
							{
								lightness: 16,
							},
						],
					},
					{
						featureType: "transit",
						elementType: "geometry",
						stylers: [
							{
								color: "#000000",
							},
							{
								lightness: 19,
							},
						],
					},
					{
						featureType: "water",
						elementType: "geometry",
						stylers: [
							{
								color: "#000000",
							},
							{
								lightness: 17,
							},
						],
					},
				],
			}
		} else {
			return {
				panControl: false,
				mapTypeControl: false,
				scrollwheel: false,
				streetViewControl: true,
				streetViewControlOptions: {
					position: maps.ControlPosition.RIGHT_BOTTOM,
				},
				styles: [
					{
						featureType: "water",
						elementType: "geometry",
						stylers: [
							{
								color: "#e9e9e9",
							},
							{
								lightness: 17,
							},
						],
					},
					{
						featureType: "landscape",
						elementType: "geometry",
						stylers: [
							{
								color: "#f5f5f5",
							},
							{
								lightness: 20,
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "geometry.fill",
						stylers: [
							{
								color: "#ffffff",
							},
							{
								lightness: 17,
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "geometry.stroke",
						stylers: [
							{
								color: "#ffffff",
							},
							{
								lightness: 29,
							},
							{
								weight: 0.2,
							},
						],
					},
					{
						featureType: "road.arterial",
						elementType: "geometry",
						stylers: [
							{
								color: "#ffffff",
							},
							{
								lightness: 18,
							},
						],
					},
					{
						featureType: "road.local",
						elementType: "geometry",
						stylers: [
							{
								color: "#ffffff",
							},
							{
								lightness: 16,
							},
						],
					},
					{
						featureType: "poi",
						elementType: "geometry",
						stylers: [
							{
								color: "#f5f5f5",
							},
							{
								lightness: 21,
							},
						],
					},
					{
						featureType: "poi.park",
						elementType: "geometry",
						stylers: [
							{
								color: "#dedede",
							},
							{
								lightness: 21,
							},
						],
					},
					{
						elementType: "labels.text.stroke",
						stylers: [
							{
								visibility: "on",
							},
							{
								color: "#ffffff",
							},
							{
								lightness: 16,
							},
						],
					},
					{
						elementType: "labels.text.fill",
						stylers: [
							{
								saturation: 36,
							},
							{
								color: "#333333",
							},
							{
								lightness: 40,
							},
						],
					},
					{
						elementType: "labels.icon",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "transit",
						elementType: "geometry",
						stylers: [
							{
								color: "#f2f2f2",
							},
							{
								lightness: 19,
							},
						],
					},
					{
						featureType: "administrative",
						elementType: "geometry.fill",
						stylers: [
							{
								color: "#fefefe",
							},
							{
								lightness: 20,
							},
						],
					},
					{
						featureType: "administrative",
						elementType: "geometry.stroke",
						stylers: [
							{
								color: "#fefefe",
							},
							{
								lightness: 17,
							},
							{
								weight: 1.2,
							},
						],
					},
				],
			}
		}
	}

	// Email form logic below
	const form = useRef<HTMLFormElement>(null)

	const [showLabel, setShowLabel] = useState<boolean>(false)
	const [labelText, setLabelText] = useState<string>("Email sent!")

	// Checking whether inputs are empty
	const [email, setEmail] = useState<string>("")
	const [name, setName] = useState<string>("")
	const [message, setMessage] = useState<string>("")

	const sendEmail = (e: any) => {
		e.preventDefault()

		if (name === "") {
			setShowLabel(true)
			setLabelText("Please type in your name")
		}

		if (email === "") {
			setShowLabel(true)
			setLabelText("Please type in your email")
		}

		if (message === "") {
			setShowLabel(true)
			setLabelText("Please type in your message")
		}

		emailjs
			.sendForm(
				"service_3s1dstg",
				"template_adx2wrs",
				form.current || "",
				"cV4w4uc6VzBgmXQWZ"
			)
			.then(
				(result) => {
					console.log(result.text)
				},
				(error) => {
					console.log(error.text)
				}
			)

		if (name !== "" && email !== "" && message !== "") {
			setShowLabel(true)
			setLabelText("Email sent!")
			setTimeout(() => {
				window.location.reload()
			}, 2000)
		}
	}

	// Handling send email button on hover

	const [arrowColor, setArrowColor] = useState<boolean>(false)

	const handleButtonHover = () => {
		setArrowColor((old) => !old)
	}

	return (
		<div className="bg-tofuLightGrey">
			<Navbar />
			<Header title={t("title")} description="" />
			<Helmet>
				<title>TOFU Media</title>
				<meta name="description" content={"TOFU Mediaのお問い合わせ情報は、こちらをご覧ください。お客様のプロジェクトや案件につきまして、特別なご要望がございましたら、お問い合わせフォームまたはお問い合わせ用メールアドレスをご利用ください。"} />
			</Helmet>
			<div className="mx-8 lg:mx-48 my-20 lg:my-48 flex flex-col lg:flex-row text-tofuLightWhite font-syne">
				<div className="flex flex-col lg:mr-36">
					<div>
						<p className="font-black text-lg lg:text-3xl leading-[1.1]">
							{t("why_not")}
						</p>
						<p
							className="text-lg md:text-xl mt-4 lg:mt-8 leading-[1.1]"
							dangerouslySetInnerHTML={{ __html: t("write-email") }}
						></p>
						<b className="text-lg md:text-xl leading-[1.1]">
							contact@tofu.media
						</b>
						<br /> <br />
						<p className="text-lg md:text-xl leading-[1.1]">
							<b>tel. </b>03-6884-4732
						</p>
					</div>
					<div>
						<p className="font-black text-lg lg:text-3xl leading-[1.1] mt-10 lg:mt-48">
							{t("our_office")}
						</p>
						<p
							className="text-lg md:text-xl mt-4 lg:mt-8 mb-16 lg:mb-0 leading-[1.1]"
							dangerouslySetInnerHTML={{ __html: t("address") }}
						></p>
					</div>
				</div>
				<div className="w-full h-[65vh]">
					<GoogleMapReact
						bootstrapURLKeys={{
							key: "AIzaSyD7ACBwILY8jvHZXW_Ym0ZL_bLb5zSpMaI",
						}}
						defaultCenter={defaultProps.center}
						defaultZoom={defaultProps.zoom}
						options={createMapOptions}
					>
						<RiMapPin2Fill
							lat={35.655653374355694}
							lng={139.71498609809257}
							color="#47b844"
							size={40}
						/>
					</GoogleMapReact>
				</div>
			</div>
			<div className="relative mx-8 lg:mx-48 my-20 lg:my-48 flex flex-col lg:flex-row text-tofuLightWhite font-syne">
				<p className="font-black text-lg lg:text-3xl leading-[1.1] mt-10 lg:mt-48 min-w-[45rem]">
					{t("contact_form")}
				</p>
				<form ref={form} className="flex  flex-col  w-full">
					<label className="text-left text-xl font-normal mt-4 ml:mt-0">
						{t("contact_name")}
					</label>
					<input
						className="outline-none bg-transparent mb-16 leading-[3rem] text-2xl border-b-2 border-tofuLightWhite h-12 px-6"
						type="text"
						name="name"
						placeholder=""
						onChange={(e) => setName(e.target.value)}
					/>
					<label className="text-left text-xl font-normal">
						{t("contact_email")}
					</label>
					<input
						className="outline-none bg-transparent mb-16 leading-[3rem] text-2xl border-b-2 border-tofuLightWhite h-12 px-6"
						type="text"
						name="email"
						placeholder=""
						onChange={(e) => setEmail(e.target.value)}
					/>
					<label className="text-left text-xl font-normal">{t("contact_message")}</label>
					<textarea
						className="outline-none bg-transparent resize-none z-20 text-2xl h-[9rem] mb-16 overflow-hidden leading-[3rem] border-b-2 border-tofuLightWhite px-6"
						name="message"
						placeholder=""
						onChange={(e) => setMessage(e.target.value)}
					/>
					<hr
						className={` ${colorMode === "dark" ? "h-[2px]" : "h-[3px]"
							} bg-tofuLightWhite relative z-40 mt-[-10rem]`}
					/>
					<hr
						className={` ${colorMode === "dark" ? "h-[2px]" : "h-[3px]"
							} bg-tofuLightWhite relative z-30 mt-[2.8rem]`}
					/>
					<div className="w-full flex justify-end">
						<button
							onMouseEnter={handleButtonHover}
							onClick={sendEmail}
							onMouseLeave={handleButtonHover}
							className={`mt-[6rem] flex flex-row justify-self-end justify-center items-center text-xl border-2 rounded-full w-[9rem] py-1`}
							style={{
								borderColor: arrowColor
									? "#47B844"
									: colorMode === "light"
										? "black"
										: "white",
							}}
						>
							{t("send")}
							{arrowColor ? (
								<img className="ml-2" src={ArrowGreen} alt="Arrow" />
							) : (
								<img
									className={`${colorMode === "light" && "invert"} ml-2`}
									src={Arrow}
									alt="Arrow"
								/>
							)}
						</button>
					</div>
				</form>
			</div>
			{showLabel && (
				<div className="z-[9999] w-[25rem] h-32 bg-tofuGreen p-4 fixed right-4 bottom-4 font-syne text-xl text-white">
					{labelText}
				</div>
			)}

			<Footer />
		</div>
	)
}

export default contact
